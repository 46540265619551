import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_CaKIoANnI2 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/router.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import oruga_gUpAr85HKs from "/opt/build/repo/plugins/oruga.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_CaKIoANnI2,
  chunk_reload_client_UciE0i6zes,
  oruga_gUpAr85HKs
]